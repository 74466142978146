import { NgModule } from '@angular/core';
import { provideVudToastOptions } from '@vismaux/ngx-vud';

import { ToasterTemplatesComponent } from '#shared/components/toaster/toaster-templates.component';

@NgModule({
  imports: [ToasterTemplatesComponent],
  exports: [ToasterTemplatesComponent],
  providers: [
    provideVudToastOptions({
      position: 'bottom-left',
    }),
  ],
})
export class ToasterModule {}
