<div class="container mt-24">
  <div class="row error-page align-items-center">
    <div class="error-page-content col-12 col-md-6">
      <h1 i18n>An error has occured</h1>
      <p i18n>
        We're sorry, an unexpected error has occurred and it has been logged. We will try to fix it as soon as possible.
      </p>
      <button class="btn btn-primary" routerLink="overview" i18n>Back to overview</button>
    </div>
    <div class="error-page-image col-12 col-md-6">
      <img src="../../../assets/icons/server_error.svg" alt="An error has occured" i18n-alt />
    </div>
  </div>
</div>
