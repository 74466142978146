<ng-template let-context let-hide="hide" let-id="id" #successTemplate>
  <span>{{ context.message }}</span>
  <button class="close" type="button" (click)="hide(id)">x</button>
</ng-template>

<ng-template let-context let-hide="hide" let-id="id" #infoTemplate>
  <span>{{ context.message }}</span>
  <button class="close" type="button" (click)="hide(id)"></button>
</ng-template>

<ng-template let-context let-hide="hide" let-id="id" #WarningTemplate>
  <span>{{ context.message }}</span>
  <button class="close" type="button" (click)="hide(id)"></button>
</ng-template>

<ng-template let-context let-error="error" let-hide="hide" let-id="id" #errorTemplate>
  {{ context.message }}
  <br />
  @if (error) {
    <small>{{ error | json }}</small>
  }
  <button class="close" type="button" (click)="hide(id)"></button>
</ng-template>
