import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { UserClient } from '@bo-schema-api-dto';
import { take } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LiveLeaderChatService {
  window: Window;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private userClient: UserClient,
  ) {}

  private configure(visitorData: string) {
    this.chat('visitor-data', visitorData);
    this.chat('launcher', this.launcher, true);
    this.chat('onModeChange', this.onModeChange);
    this.chat('onUnread', this.onUnread);
    this.chat('team', '6RpNXAiXRKWNvqh7VodhlQ');
    this.chat('skip-prechat-survey');
    this.chat('size', [500, 650]);
  }

  setup() {
    this.loadScript();
    window.lvchtarr = window.lvchtarr || [];
    this.userClient
      .getLiveLeaderToken()
      .pipe(take(1))
      .subscribe((token) => {
        if (token !== null) {
          this.configure(token);
        }
      });
  }

  private chat(...args: LiveLeaderConfigArray) {
    window.lvchtarr.push(args);
  }

  private launcher(data: ILiveLeaderData) {
    const launcher = document.getElementById('liveLeader-launcher');
    if (data.canOpen) {
      launcher?.addEventListener('click', () => data.openFn(launcher));
      data.openFn(launcher);
    }
  }

  private onModeChange(mode: LiveLeaderChatModeEnum): void {
    const launcherClass = document.getElementById('liveLeader-launcher').classList;
    mode === LiveLeaderChatModeEnum.Full
      ? launcherClass.add('active', 'd-block')
      : launcherClass.remove('active');
  }

  private onUnread(count: number) {
    const launcherClass = document.getElementById('liveLeader-launcher').classList;
    count > 0 ? launcherClass.add('attention') : launcherClass.remove('attention');
  }

  private loadScript() {
    const script: HTMLScriptElement = document.getElementById('launcherFrame') as HTMLScriptElement;

    if (!script) {
      const liveleaderScript = document.createElement('script');
      liveleaderScript.async = true;
      liveleaderScript.src = 'https://client.visma.chat/embed.js';
      liveleaderScript.setAttribute('data-chat-id', 'ID7wGSBafxSeidOJP7-bw9iw');
      this.document.head.appendChild(liveleaderScript);
    }
  }
}

export interface ILiveLeaderData {
  name: string;
  // image?: any;
  online: boolean;
  canOpen: boolean;
  greeting: string;

  openFn(target: HTMLElement): void;
}

export enum LiveLeaderChatModeEnum {
  Launcher = 'launcher',
  Full = 'full',
}

declare global {
  interface Window {
    lvchtarr: LiveLeaderConfigArray[];
  }
}

type LiveLeaderConfigArray = [LiveLeaderCommand, ...LiveLeaderCommandArgument[]];

type LiveLeaderCommand =
  | 'visitor-data'
  | 'launcher'
  | 'onModeChange'
  | 'onUnread'
  | 'team'
  | 'skip-prechat-survey'
  | 'size';

type LiveLeaderCommandArgument =
  | string
  | number
  | boolean
  | [number, number]
  | ((arg: number | LiveLeaderChatModeEnum | ILiveLeaderData) => void);
