import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  VUD_ICON_CONFIG,
  VismaUnifiedDesignModule,
  VudDateAdapter,
  VudDateNativeUTCAdapter,
  VudDrawerModule,
  VudIconConfig,
  VudModalModule,
} from '@vismaux/ngx-vud';
import { LicenseManager } from 'ag-grid-enterprise';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { FeatureFlagModule } from '@data-import/feature-flag';

import { ToasterModule } from '#shared/components/toaster/toaster.module';
import { FormFieldErrorDirective } from '#shared/features/form-field';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EmployeesListGetEmployeesErrorComponent } from './components/employees-list-get-employees-error/employees-list-get-employees-error.component';
import { FileImportGridComponent } from './components/file-import-grid/file-import-grid.component';
import { HeaderComponent } from './components/header/header.component';
import { LayoutComponent } from './components/layout/layout.component';
import { CoreModule } from './core/core.module';
import { EmployeeActionsToastService } from './modules/employee/employee-card/services/employee-actions-toast.service';
import { AuthenticationErrorComponent } from './pages/authentication-error/authentication-error.component';
import { BetaComponent } from './pages/beta/beta.component';
import { ErrorComponent } from './pages/error/error.component';
import { ServiceUnavailableComponent } from './pages/service-unavailable/service-unavailable.component';
import { SettingsGridComponent } from './pages/settings-grid/settings-grid.component';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    UnauthorizedComponent,
    ServiceUnavailableComponent,
    LayoutComponent,
    ErrorComponent,
    AuthenticationErrorComponent,
    BetaComponent,
    EmployeesListGetEmployeesErrorComponent,
    FileImportGridComponent,
    SettingsGridComponent,
  ],
  providers: [
    { provide: VudDateAdapter, useClass: VudDateNativeUTCAdapter },

    {
      provide: VUD_ICON_CONFIG,
      useValue: { type: 'dynamic' } as VudIconConfig,
    },
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    VismaUnifiedDesignModule,
    CoreModule,
    SharedModule,
    FeatureFlagModule,
    VudModalModule,
    InfiniteScrollModule,
    VudDrawerModule,
    FormFieldErrorDirective,
    ToasterModule,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private employeeActionsToastService: EmployeeActionsToastService) {
    LicenseManager.setLicenseKey(
      'Using_this_{AG_Grid}_Enterprise_key_{AG-052066}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Visma_Software_AS}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Visma.net_Payroll_&_HRM}_only_for_{3}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Visma.net_Payroll_&_HRM}_need_to_be_licensed___{Visma.net_Payroll_&_HRM}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{20_February_2025}____[v3]_[01]_MTc0MDAwOTYwMDAwMA==a66339e405ff3506e087db8dd9a8e0fe',
    );
  }
}
