import { Injectable, Renderer2, RendererFactory2, inject } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private renderer: Renderer2;
  protected readonly router = inject(Router);
  protected readonly rendererFactory = inject(RendererFactory2);

  constructor() {
    this.renderer = this.rendererFactory.createRenderer(null, null);
    this.trackRouterChanges();
  }

  public trackRouterChanges(): void {
    this.router.events.pipe(filter((ev) => ev instanceof NavigationEnd)).subscribe((ev) => {
      const navEndUrl = (ev as NavigationEnd).urlAfterRedirects.split('/')[1];
      this.updateNavSpaceClass(navEndUrl);
    });
  }

  private updateNavSpaceClass(route: string): void {
    if (['settings', 'item-cache'].includes(route)) {
      this.renderer.removeClass(document.body, 'nav-space');
      this.renderer.addClass(document.body, 'nav-space-settings');
    } else {
      this.renderer.removeClass(document.body, 'nav-space-settings');
      this.renderer.addClass(document.body, 'nav-space');
    }
  }
}
