import { Component, OnInit, inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CookieService } from 'ngx-cookie-service';
import { Observable, take } from 'rxjs';
import { map } from 'rxjs/operators';

import {
  MenuItemDto,
  OperationTypeEnum,
  OrgContextDto,
  SessionDto,
  UserClient,
} from '@data-import/data-access/bulk-operations-api';
import { FeatureFlagService } from '@data-import/feature-flag';

import { environment } from '../../../environments/environment';
import { changeLanguage, getLocalePreference } from '../../../localization.helper';
import { DisabledOperationService } from '../../core/services/disabled-operation.service';
import { LiveLeaderChatService } from '../../core/services/live-leader-chat.service';
import { UserSessionService } from '../../core/services/user-session.service';
import { WalkmeService } from '../../core/services/walkme.service';
import { SelectedEmployeeService } from '../../modules/employee/employee-card/services/selected-employee.service';
import { LanguagesEnum } from '../../shared/localizers/languages.enum';

@Component({
  selector: 'di-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
@UntilDestroy()
export class HeaderComponent implements OnInit {
  user$: Observable<SessionDto>;
  userCompany$: Observable<OrgContextDto>;
  environment = environment;
  menuItems$: Observable<MenuItemDto[]>;
  isLiveLeaderReady: boolean;
  userLanguage = getLocalePreference();
  isAbsenceImportDisabled: boolean;
  isVacationBalanceDisabled: boolean;
  isFixedTransactionListHidden: boolean;
  protected readonly selectedEmployeeService = inject(SelectedEmployeeService);

  constructor(
    public userSessionService: UserSessionService,
    private cookieService: CookieService,
    private userClient: UserClient,
    private router: Router,
    private liveLeaderChatService: LiveLeaderChatService,
    private walkmeService: WalkmeService,
    private title: Title,
    private disabledOperationService: DisabledOperationService,
    private featureFlagService: FeatureFlagService,
  ) {}

  ngOnInit() {
    this.user$ = this.userSessionService.getSession();
    this.userCompany$ = this.userSessionService.getUserCompany();
    this.menuItems$ = this.userClient
      .getMenuItems()
      .pipe(
        map((response) => response.menuItems.sort((a, b) => a.menuSortOrder - b.menuSortOrder)),
      );
    if (this.userLanguage !== LanguagesEnum.No) {
      this.title.setTitle('Employees');
    }
    this.disabledOperationService
      .getDisabledOperations()
      .pipe(take(1))
      .subscribe((operations) => {
        this.isAbsenceImportDisabled = operations[OperationTypeEnum.AbsenceRegistrationImport];
        this.isVacationBalanceDisabled = operations[OperationTypeEnum.VacationBalanceImport];
      });

    this.featureFlagService
      .getFlagValue('enable-fixed-transaction-list')
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.isFixedTransactionListHidden = !value;
      });
  }

  changeLanguage() {
    changeLanguage();
    window.location.reload();
  }

  selectCompany(company: OrgContextDto): void {
    const firstRouteParam = this.collectRouteParams()[0];
    const newUrl = this.router.url.split(firstRouteParam)[0];
    window.location.href = `${environment.loginRedirectUrl}/${company.id}${newUrl}`;
  }

  logout() {
    this.cookieService.deleteAll();
  }

  goToAbout() {
    window.open(`${environment.vismaAboutUrl}${this.userSessionService.companyId}/about`, '_blank');
  }

  openLiveLeader() {
    if (!this.isLiveLeaderReady) {
      this.liveLeaderChatService.setup();
      this.isLiveLeaderReady = true;
    }
  }

  openWalkme() {
    this.walkmeService.open();
  }

  collectRouteParams(): string[] {
    // Method from Angular docs: https://angular.io/api/router/ActivatedRouteSnapshot
    let params = {};
    const stack: ActivatedRouteSnapshot[] = [this.router.routerState.snapshot.root];
    while (stack.length > 0) {
      // originally stack.pop()!, but non-null assertion is forbidden by lint
      const route = stack.pop();
      params = { ...params, ...route.params };
      stack.push(...route.children);
    }
    // originally params, but only the values are needed
    return Object.values(params);
  }

  openLegacyEM(): string {
    const employeeNumber = this.selectedEmployeeService.employeeNumber();
    let url = environment.vismaEmpmanUrl + '#/' + this.userSessionService.companyId;
    if (employeeNumber) {
      url = url + '/employee/' + employeeNumber;
    }
    return url;
  }
}
