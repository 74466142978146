import { APP_BASE_HREF, registerLocaleData } from '@angular/common';
import enGbLocale from '@angular/common/locales/en-GB';
import nbLocale from '@angular/common/locales/nb';
import { ApplicationRef, LOCALE_ID, enableProdMode } from '@angular/core';
import { loadTranslations } from '@angular/localize';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { devTools } from '@ngneat/elf-devtools';

import {
  CallCorrelationIdHeaderName,
  SessionCorrelationIdHeaderName,
  SessionDto,
  vismaCallId,
  vismaSessionId,
} from '@data-import/data-access/bulk-operations-api';

import { AppModule } from './app/app.module';
import { USER_SESSION } from './app/core/core.module';
import { environment } from './environments/environment';
import {
  getPreferredOdpOrgId,
  redirectFromUnauthorizedOrAuthenticationError,
  redirectToAuthenticationErrorPage,
  redirectToLoginPage,
  redirectToServiceUnavailablePage,
  redirectToUnauthorizedPage,
  removeQueryParam,
} from './href.helper';
import { getAngularFileLocaleName, getLocalePreference } from './localization.helper';
import { initLogging, setLoggingContext } from './logger';

initLogging();

redirectFromUnauthorizedOrAuthenticationError();

if (environment.production) {
  enableProdMode();
}

let preferredOdpOrgId = getPreferredOdpOrgId();

fetch(
  `${environment.dataImportApiBaseUrl}/session${
    preferredOdpOrgId ? '?org=' + preferredOdpOrgId : ''
  }`,
  {
    credentials: 'include',
    headers: {
      [CallCorrelationIdHeaderName]: vismaCallId(),
      [SessionCorrelationIdHeaderName]: vismaSessionId(),
    },
  },
)
  .then((res) => {
    if ([401, 403].includes(res.status)) {
      redirectToLoginPage();
    } else if (res.status === 503) {
      console.warn(`Service unavailable.`);
      redirectToServiceUnavailablePage();
    } else if (res.status !== 200) {
      console.error(`Authorization failed with [${res.status}] status.`);
      redirectToAuthenticationErrorPage();
    }
    return res;
  })
  .then((userSessionResponse) => {
    bootstrapApp(userSessionResponse);
  })
  .catch((err) => {
    console.error(err);
    redirectToAuthenticationErrorPage();
    return err;
  });

async function bootstrapApp(userSessionResponse) {
  const session =
    userSessionResponse?.status === 200
      ? new SessionDto(await userSessionResponse.json().catch((err) => console.error(err)))
      : undefined;

  const userLanguage = getLocalePreference(session?.languageCode);
  const locale = await import(`./locale/messages.${userLanguage}.json`);

  preferredOdpOrgId = preferredOdpOrgId || getPreferredOdpOrgId(session);

  setLoggingContext(preferredOdpOrgId, session);

  removeQueryParam('contextSelector:switchContext');

  if (session && preferredOdpOrgId === '') {
    console.error('User does not have access to any contexts.');
    redirectToUnauthorizedPage();
  } else if (
    session &&
    !session.availableContexts?.some((context) => context.id.toString() === preferredOdpOrgId)
  ) {
    console.error(`User does not have access to company with OdpOrgId ${preferredOdpOrgId}.`);
    redirectToUnauthorizedPage();
  }

  loadTranslations(locale.translations || locale.default.translations);

  platformBrowserDynamic([
    {
      provide: APP_BASE_HREF,
      useFactory: () => '/' + preferredOdpOrgId,
    },
    {
      provide: USER_SESSION,
      useValue: session,
    },
    { provide: LOCALE_ID, useValue: getLocalePreference() },
  ])
    .bootstrapModule(AppModule)
    .then((moduleRef) => {
      if (!environment.production) {
        devTools({
          postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
        });
      }
    })
    .catch((err) => console.error(err));

  /*
   * esbuild does not support "magic" dynamic imports: https://github.com/evanw/esbuild/issues/1240
   * but only for a few locale imports, that's not a huge impact on the bundle size
   */
  switch (getAngularFileLocaleName()) {
    case 'en-GB':
      registerLocaleData(enGbLocale);
      break;
    case 'nb':
      // nb-NO does not exist in Angular - only nb is used
      registerLocaleData(nbLocale);
      break;
  }
}
