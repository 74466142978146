import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';

@Component({
  selector: 'di-authentication-error',
  templateUrl: './authentication-error.component.html',
  styleUrls: ['./authentication-error.component.scss'],
})
export class AuthenticationErrorComponent {
  backToHome() {
    window.location.href = environment.vismaHomeUrl;
  }
}
